import React from 'react';
import classNames from 'classnames';
import layoutStyles from '../../../../stylesheets/components/layout.module.scss';
import linkStyles from '../../../../stylesheets/portals/modules/login/link.module.scss';

const LinkIconLight = () => {
  return (
    <svg
      className={linkStyles.iconLight}
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.133545"
        y="0.174316"
        width="56"
        height="56"
        rx="18"
        fill="#F2F6FF"
      ></rect>
      <path
        d="M22.7548 19.7106V17.1951M19.3158 23.1496H16.8003"
        stroke="#1472FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M34.8472 41.1932V38.6777M40.7998 35.2368H38.283"
        stroke="#1472FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M21.7272 26.6484L20.4156 27.96C17.7651 30.6104 17.7651 34.9306 20.4156 37.581C23.0569 40.2223 27.3861 40.2302 30.0352 37.581L31.3481 36.2694"
        stroke="#1472FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M35.713 31.9014L37.0336 30.5808C39.675 27.9394 39.684 23.6102 37.0336 20.9598C34.3923 18.3197 30.0631 18.3106 27.4127 20.9598L26.092 22.2805"
        stroke="#1472FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

const LinkIconDark = () => {
  return (
    <svg
      className={linkStyles.iconDark}
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.174316"
        width="56"
        height="56"
        rx="18"
        fill="white"
        fillOpacity="0.07"
      ></rect>
      <path
        d="M22.6213 19.7106V17.1951M19.1823 23.1496H16.6667"
        stroke="#3687FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M34.7136 41.1932V38.6777M40.6663 35.2368H38.1495"
        stroke="#3687FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M21.5936 26.6484L20.282 27.96C17.6316 30.6104 17.6316 34.9306 20.282 37.581C22.9234 40.2223 27.2526 40.2302 29.9017 37.581L31.2146 36.2694"
        stroke="#3687FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M35.5794 31.9014L36.9001 30.5808C39.5414 27.9394 39.5505 23.6102 36.9001 20.9598C34.2588 18.3197 29.9296 18.3106 27.2792 20.9598L25.9585 22.2805"
        stroke="#3687FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

const LinkIcon = () => {
  return (
    <div className={classNames(layoutStyles.mb20, layoutStyles.flex)}>
      <LinkIconLight />
      <LinkIconDark />
    </div>
  );
};

export default LinkIcon;
